<template>
    <div class='terms-condition'>
        <Navigation :showSearch="false"></Navigation>

        <div class="content">
            <h1>
                Terms and Condition
            </h1>


           <div class="side-nav">
                <h3 class="item-a">
                    <a href="#modification">
                        Modification of these Terms
                    </a>
                </h3>

                 <h3 class="item-a">
                    <a href="#account-registration">
                        Account Registration
                    </a>
                </h3>

                 <h3 class="item-a">
                    <a href="#tc-content">
                        Content
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#service-fee">
                        Service Fees
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#tc-hosts-terms">
                        Terms Specific for Hosts
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#tc-guest-terms">
                        Terms Specific for Guests
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#booking-cancellations">
                        Booking Cancellations and Refunds
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#rounding-off">
                        Rounding off
                    </a>
                </h3>

                <h3 class="item-a">
                    <a href="#termination">
                        Term and Termination, Suspension and other Measures
                    </a>
                </h3>

                
                
           </div>


           <!-- <select>
               <option value="community">Community</option>
           </select> -->

<div>
    <h2>  1. Scope of the Services</h2>
    <p>

         
    By using or accessing Bongalo platform, a subdomains, any mobile application for such websites or any other website operated by us on which these Terms and Conditions are posted via a link, you acknowledge and agree that you are subject to the following terms and conditions, as well as our Privacy Policy, which also governs your use of the Site, and is incorporated by reference (These Terms and Conditions with the Privacy Policy shall be referred to as the "Terms"). Please read these Terms carefully, as they contain important information about limitations of liability and resolution of disputes through arbitration rather than court.

<ul>
    <li>
        1.1 The Bongalo Platform is an online marketplace that enables registered users (“Members'') and certain third parties who offer services (Members and third parties who offer services are “Hosts” and the services they offer are “Host Services”) to publish such Host Services on the Bongalo Platform (“Listings”) and to communicate and transact directly with Members that are seeking to book such Host Services (Members using Host Services are “Guests”). Host Services may include the offering of vacation, apartments,  homes or other properties for use ("Accommodations"), access to unique events and locations (“Events' '), and a variety of other travel and non-travel related services.
    </li>

    <li>
        1.2 We urge all users to be responsible about their use of this platform and any transaction entered into as a result of either listing a property or renting a property. Bongalo does not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any Listings or Host Services, nor is Bongalo an organiser or retailer of travel packages. Hosts alone are responsible for their Listings and Host Services. When Members make or accept a booking, they are entering into a contract directly with each other. Bongalo is not and does not become a party to or other participant in any contractual relationship between Members, nor is Bongalo a real estate broker or insurer. Bongalo is not acting as an agent in any capacity for any Member, except as specified in the payment terms facilitated by our partners.
    </li>

    <li>
        1.3 While we may help facilitate the resolution of disputes, Bongalo has no control over and does not guarantee (i) the existence, quality, safety, suitability, or legality of any Listings or Host Services, (ii) the truth or accuracy of any Listing descriptions, Ratings, Reviews, or other Member Content (as defined below), or (iii) the performance or conduct of any Member or third party. Bongalo does not endorse any Member, Listing or Host Services. Any references to a Member being "verified" (or similar language) only indicate that the Member has completed a relevant verification or identification process on Bongalo and nothing else. Any such description is not an endorsement, certification or guarantee by Bongalo about any Member, including of the Member's identity or background or whether the Member is trustworthy, safe or suitable. You should always exercise due diligence and care when deciding whether to stay in an Accommodation, participate in an Experience or Event or use other Host Services, accept a booking request from a Guest, or communicate and interact with other Members, whether online or in person. Verified Images (as defined below) are intended only to indicate a photographic representation of a Listing at the time the photograph was taken, and are therefore not an endorsement by Bongalo of any Host or Listing.
    </li>

    <li>
        1.4 If you choose to use the Bongalo Platform as a Host, your relationship with Bongalo is limited to being an independent, third-party contractor, and not an employee, agent, joint venturer or partner of Bongalo for any reason, and you act exclusively on your own behalf and for your own benefit, and not on behalf, or for the benefit, of Bongalo. Bongalo does not, and shall not be deemed to, direct or control you generally or in your performance under these Terms specifically, including in connection with your provision of the Host Services. You acknowledge and agree that you have complete discretion whether to list Host Services or otherwise engage in other business or employment activities.
    </li>

    <li>
        1.5 To promote the Bongalo Platform and to increase the exposure of Listings to potential Guests, Listings and other Member Content may be displayed on other websites, in applications, within emails, and in online and offline advertisements. To assist Members who speak different languages, Listings and other Member Content may be translated, in whole or in part, into other languages. Bongalo cannot guarantee the accuracy or quality of such translations and Members are responsible for reviewing and verifying the accuracy of such translations. The Bongalo Platform may contain translations powered by Google. Google disclaims all warranties related to the translations, express or implied, including any warranties of accuracy, reliability, and any implied warranties for merchantability, fitness for a particular purpose and non-infringement.
    </li>

    <li>
        1.6 The Bongalo Platform may contain links to third-party websites or resources (“Third-Party Services”). Such Third-Party Services may be subject to different terms and conditions and privacy practices. Bongalo is not responsible or liable for the availability or accuracy of such Third-Party Services, or the content, products, or services available from such Third-Party Services. Links to such Third-Party Services are not an endorsement by Bongalo of such Third-Party Services.
    </li>
    <li>
        1.7 Due to the nature of the Internet, Bongalo cannot guarantee the continuous and uninterrupted availability and accessibility of the Bongalo Platform. Bongalo may restrict the availability of the Bongalo Platform or certain areas or features thereof, if this is necessary in view of capacity limits, the security or integrity of our servers, or to carry out maintenance measures that ensure the proper or improved functioning of the Bongalo Platform. Bongalo may improve, enhance and modify the Bongalo Platform and introduce new Bongalo Services from time to time.
    </li>
</ul>
    </p>
</div>

<div>
    <h2>2. Eligibility, Using the Bongalo Platform, Member Verification</h2>
    <p>
        <ul>
            <li>
                2.1 In order to access and use the Bongalo Platform or register an Bongalo Account you must be an individual at least 18 years old or a duly organized, validly existing business, organization or other legal entity in good standing under the laws of the country you are established and able to enter into legally binding contracts.
            </li>
            <li>
                2.3 Bongalo may make access to and use of the Bongalo Platform, or certain areas or features of the Bongalo Platform, subject to certain conditions or requirements, such as completing a verification process, meeting specific quality or eligibility criteria, meeting Ratings or Reviews thresholds, or a Member’s booking and cancellation history.
            </li>
            <li>
                2.4 User verification on the Internet is difficult and we do not assume any responsibility for the confirmation of any Member’s identity. Notwithstanding the above, for safety,  transparency and fraud prevention purposes, and as permitted by applicable laws, we may, but have no obligation to (i) ask Members to provide a form of government identification or other information or undertake additional checks designed to help verify the identities or backgrounds of Members, (ii) screen Members against third party databases or other sources and request reports from service providers, and (iii) where we have sufficient information to identify a Member, obtain reports from public records of criminal convictions or sex offender registrations or an equivalent version of background or registered sex offender checks in your local jurisdiction (if available).
            </li>
            <li>
                2.5 The access to or use of certain areas and features of the Bongalo Platform may be subject to separate policies, standards or guidelines, or may require that you accept additional terms and conditions, before you can access the relevant areas or features of the Bongalo Platform. If there is a conflict between these Terms and terms and conditions applicable to a specific area or feature of the Bongalo Platform, the latter terms and conditions will take precedence with respect to your access to or use of that area or feature, unless specified otherwise in the latter terms and conditions.
            </li>
        </ul>





    </p>
</div>


<div id="modification">
    <h2>
        3. Modification of these Terms
    </h2>
    <p>
        <ul>
            
            <li>
                Bongalo reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the Bongalo Platform and update the “Last Updated” date at the top of these Terms. We will also provide you with notice of the modifications by email at least thirty (30) days before the date they become effective. If you disagree with the revised Terms, you may terminate this Agreement with immediate effect. We will inform you about your right to terminate the Agreement in the notification email. If you do not terminate your Agreement before the date the revised Terms become effective, your continued access to or use of the Bongalo Platform will constitute acceptance of the revised Terms.
            </li>

        </ul>
    </p>
</div>

<div id="account-registration">
    <h2>
        4. Account Registration
    </h2>
    <p>
        <ul>
            <li>
                4.1 You must register an account ("Bongalo Account") to access and use certain features of the Bongalo Platform, such as publishing or booking a Listing. If you are registering a Bongalo Account for a business, organization or other legal entity, you represent and warrant that you have the authority to legally bind that entity and grant us all permissions and licenses provided in these Terms.
            </li>
            <li>
                4.2 You can register a Bongalo Account using an email address and creating a password, or through your account with certain third-party social networking services, such as Facebook or Google ("SNS Account ''). You have the ability to disable the connection between your Bongalo Account and your SNS Account at any time, by accessing the "Settings'  section of the Bongalo Platform.
            </li>

            <li>
                4.3 You must provide accurate, current and complete information during the registration process and keep your Bongalo Account and public Bongalo Account profile page information up-to-date at all times.
            </li>

            <li>
                4.4 You may not register more than one (1) Bongalo Account unless Bongalo authorizes you to do so. You may not assign or otherwise transfer your Bongalo Account to another party.
            </li>
            <li>
                4.5 You are responsible for maintaining the confidentiality and security of your Bongalo Account credentials and may not disclose your credentials to any third party. You must immediately notify Bongalo if you know or have any reason to suspect that your credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of your Bongalo Account. You are liable for any and all activities conducted through your Bongalo Account, unless such activities are not authorized by you and you are not otherwise negligent (such as failing to report the unauthorized use or loss of your credentials).
            </li>
            <li>
                4.6 Bongalo may enable features that allow you to authorize other Members or certain third parties to take certain actions that affect your Bongalo Account. For example, we may enable Members to link their Bongalo Accounts to businesses and take actions for those businesses, we may enable eligible Members or certain third parties to book Listings on behalf of other Members, or we may enable Hosts to add other Members as Co-Hosts (as defined below) to help manage their Listings. These features do not require that you share your credentials with any other person. No third party is authorized by Bongalo to ask for your credentials, and you shall not request the credentials of another Member.
            </li>
        </ul>
    </p>
</div>

<div id="tc-content">
    <h2>
         5. Content
    </h2>
    <p>
        <ul>
           <li>
               5.1 Bongalo may, at its sole discretion, enable Members to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Bongalo Platform ("Member Content"); and (ii) access and view Member Content and any content that Bongalo itself makes available on or through the Bongalo Platform, including proprietary Bongalo content and any content licensed or authorized for use by or through Bongalo from a third party ("Bongalo Content" and together with Member Content, "Collective Content").
           </li>
           <li>
                5.2 The Bongalo Platform, Bongalo Content, and Member Content may in its entirety or in part be protected by copyright, trademark, and/or other laws of the Republic of Rwanda and other countries. You acknowledge and agree that the Bongalo Platform and Bongalo Content, including all associated intellectual property rights, are the exclusive property of Bongalo and/or its licensors or authorizing third-parties. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Bongalo Platform, Bongalo Content or Member Content. All trademarks, service marks, logos, trade names, and any other source identifiers of Bongalo used on or in connection with the Bongalo Platform and Bongalo Content are trademarks or registered trademarks of Bongalo in the Republic of Rwanda and abroad. Trademarks, service marks, logos, trade names and any other proprietary designations of third parties used on or in connection with the Bongalo Platform, Bongalo Content, and/or Collective Content are used for identification purposes only and may be the property of their respective owners.
            </li>
           <li>
               5.3 You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Bongalo Platform or Collective Content, except to the extent you are the legal owner of certain Member Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Bongalo or its licensors, except for the licenses and rights expressly granted in these Terms.
           </li>
           <li>
               5.4 Subject to your compliance with these Terms, Bongalo grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your personal device(s); and (ii) access and view any Collective Content made available on or through the Bongalo Platform and accessible to you, solely for your personal and non-commercial use.
           </li>
           <li>
               5.5 By creating, uploading, posting, sending, receiving, storing, or otherwise making available any Member Content on or through the Bongalo Platform, you grant to Bongalo a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to such Member Content to access, use, store, copy, modify, prepare derivative works of, distribute, publish, transmit, stream, broadcast, and otherwise exploit in any manner such Member Content to provide and/or promote the Bongalo Platform, in any media or platform. Insofar as Member Content (including Verified Images) includes personal information, such Member Content will only be used for these purposes if such use complies with applicable data protection laws in accordance with our Privacy Policy. Unless you provide specific consent, Bongalo does not claim any ownership rights in any Member Content and nothing in these Terms will be deemed to restrict any rights that you may have to use or exploit your Member Content.
           </li>
           <li>
               5.6 Bongalo may offer Hosts the option of having professional photographers take photographs of their Host Services, which are made available by the photographer to Hosts to include in their Listings with or without a watermark or tag bearing the words "Bongalo Verified Photo" or similar wording ("Verified Images"). You are responsible for ensuring that your Host Service is accurately represented in the Verified Images and you will stop using the Verified Images on or through the Bongalo Platform if they no longer accurately represent your Listing, if you stop hosting the Host Service featured, or if your Bongalo Account is terminated or suspended for any reason. You acknowledge and agree that Bongalo shall have the right to use any Verified Images in accordance with Section 5.5 for advertising, marketing and/or any other business purposes in any media or platform, whether in relation to your Listing or otherwise, without further notice or compensation to you. Where Bongalo is not the exclusive owner of Verified Images, by using such Verified Images on or through the Bongalo Platform, you grant to Bongalo an exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to use such Verified Images for advertising, marketing and/or any other business purposes in any media or platform, whether in relation to your Listing or otherwise, without further notice or compensation to you. Bongalo in turn grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to use Verified Images outside of the Bongalo Platform solely for your personal and non-commercial use.
           </li>
           <li>
               5.7 You are solely responsible for all Member Content that you make available on or through the Bongalo Platform. Accordingly, you represent and warrant that: (i) you either are the sole and exclusive owner of all Member Content that you make available on or through the Bongalo Platform or you have all rights, licenses, consents and releases that are necessary to grant to Bongalo the rights in and to such Member Content, as contemplated under these Terms; and (ii) neither the Member Content nor your posting, uploading, publication, submission or transmittal of the Member Content or Bongalo's use of the Member Content (or any portion thereof) as contemplated under these Terms will infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
           </li>
           <li>
               5.8 You will not post, upload, publish, submit or transmit any Member Content that: (i) is fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive; (ii) is defamatory, libelous, obscene, pornographic, vulgar or offensive; (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (iv) is violent or threatening or promotes violence or actions that are threatening to any other person or animal; (v) promotes illegal or harmful activities or substances; or (vi) violates Bongalo’s Content Policy or any other Bongalo policy. Bongalo may, without prior notice, remove or disable access to any Member Content that Bongalo finds to be in violation of applicable law, these Terms or Bongalo’s then-current Policies or Standards, or otherwise may be harmful or objectionable to Bongalo, its Members, third parties, or property.
           </li>
           <li>
               5.9 Bongalo respects copyright law and expects its Members to do the same. If you believe that any content on the Bongalo Platform infringes copyrights you own, please notify us in accordance with our Copyright Policy.
           </li>
        </ul>
    </p>
</div>

<div id="service-fee">
    <h2>
         6. Service Fees
    </h2>

    <p>
        <ul>
           <li>
               6.1 Bongalo may charge fees to Hosts ("Host Fees") and/or Guests ("service fees") in consideration for the use of the Bongalo Platform and its associated payment processing services. 
           </li>
           <li>
               6.2 Any applicable Service Fees (including any applicable Taxes) will be displayed to a Host or Guest prior to publishing or booking a Listing. Bongalo reserves the right to change the Service Fees at any time, and will provide Members adequate notice of any fee changes before they become effective. Such fee changes will not affect any bookings made prior to the effective date of the fee change.
           </li>
           <li>
               6.3 You are responsible for paying any Service Fees that you owe to Bongalo. The applicable Service Fees (including any applicable Taxes) are collected by Bongalo Payments. Bongalo Payments will deduct any Host Fees(percentage of subtotal booking amount) from the Listing Fee before remitting the payout to the Host. Any Guest Fees are included in the Total Fees collected by Bongalo Payments, paid by the guests. Service Fees are non-refundable.
           </li>
        </ul>
    </p>
</div>

<div id="tc-hosts-terms">
    <h2>
        7. Terms specific for Hosts
    </h2>

    <h4>
            7.1 Terms applicable to all Listings
    </h4>

    <p>
        
        <ul>
            
            <li>
                7.1.1 When creating a Listing through the Bongalo Platform you must (i) provide complete and accurate information about your Host Service (such as listing description, location, and calendar availability), (ii) disclose any deficiencies, restrictions (such as house rules) and requirements that apply (such as any minimum age, proficiency or fitness requirements for an Experience) and (iii) provide any other pertinent information requested by Bongalo. You are responsible for keeping your Listing information (including calendar availability) up-to-date at all times.
            </li>
            <li>
                7.1.2 You are solely responsible for setting a price (including any Taxes if applicable, or charges such as cleaning fees) for your Listing (“Listing Fee”). Once a Guest requests a booking of your Listing, you may not request that the Guest pays a higher price than in the booking request.
            </li>
            <li>
                7.1.3 Any terms and conditions included in your Listing, in particular in relation to cancellations, must not conflict with these Terms or the relevant cancellation policy for your Listing.
            </li>
            <li>
                7.1.4 Pictures, animations or videos (collectively, "Images'') used in your Listings must accurately reflect the quality and condition of your Host Services. Bongalo reserves the right to require that Listings have a minimum number of Images of a certain format, size and resolution. Bongalo also reserves the right to remove any listing previous reported by guests as having non-conforming images to the actual property. 
            </li>
            <li>
                7.1.5 The placement and ranking of Listings in search results on the Bongalo Platform may vary and depend on a variety of factors, such as Guest search parameters and preferences, Host requirements, price and calendar availability, Number and quality of Images, Reviews and Ratings, nationality Host, etc. 
            </li>
            <li>
                7.1.7 Bongalo does not provide insurance for hosts listing. Therefore it recommends that Hosts obtain appropriate insurance for their Host Services. Please review any respective insurance policy carefully, and in particular make sure that you are familiar with and understand any exclusions to, and any deductibles that may apply for, such insurance policy, including, but not limited to, whether or not your insurance policy will cover the actions or inactions of Guests (and the individuals the Guest has booked for, if applicable) while staying at your Accommodation.
            </li>
            




        </ul>

    </p>
    <h4>
        7.2 Listing Accommodations
    </h4>
    <p>
        <ul>
            <li>
                7.2.1 Unless expressly allowed by Bongalo, you may not list more than one Accommodation per Listing.
            </li>
            <li>
                7.2.2 If you choose to require a security deposit for your Accommodation, you must specify this in your Listing ("Security Deposit"). Hosts are not allowed to ask for a Security Deposit (i) after a booking has been confirmed or (ii) outside of the Bongalo Platform.
            </li>
            <li>
                7.2.3 You represent and warrant that any Listing you post and the booking of, or a Guest's stay at, an Accommodation will (i) not breach any agreements you have entered into with any third parties, such as homeowners association, condominium, or other agreements, and (ii) comply with all applicable laws, Tax requirements, and other rules and regulations (including having all required permits, licenses and registrations). As a Host, you are responsible for your own acts and omissions and are also responsible for the acts and omissions of any individuals who reside at or are otherwise present at the Accommodation at your request or invitation, excluding the Guest and any individuals the Guest invites to the Accommodation.
            </li>
        </ul>
    </p>
</div>


<div id="tc-guest-terms">
    <h2>
         8. Terms specific for Guests
    </h2>

    <h4>
        8.1 Terms applicable to all bookings
    </h4>
    <p>
        <ul>
            <li>
                8.1.1 Subject to meeting any requirements (such as completing any verification processes) set by Bongalo and/or the Host, you can book a Listing available on the Bongalo Platform by following the respective booking process. All applicable fees, including the Listing Fee, Security Deposit (if applicable), Guest Fee and any applicable Taxes (collectively, “Total Fees”) will be presented to you prior to booking a Listing. You agree to pay the Total Fees for any booking requested in connection with your Bongalo Account.
            </li>
            <li>
                8.1.2 Upon receipt of a booking confirmation from Bongalo, a legally binding agreement is formed between you and your Host, subject to any additional terms and conditions of the Host that apply, including in particular the applicable cancellation policy and any rules and restrictions specified in the Listing. Bongalo Payments will collect the Total Fees at the time of the booking request or upon the Host’s confirmation pursuant to the Payments Terms. 
            </li>
        </ul>
    </p>

    <h4>
        8.2 Booking Accommodations
    </h4>

    <p>
        <ul>
            <li>
                8.2.1 You understand that a confirmed booking of an Accommodation (“Accommodation Booking”) is a limited license granted to you by the Host to enter, occupy and use the Accommodation for the duration of your stay, during which time the Host (only where and to the extent permitted by applicable law) retains the right to re-enter the Accommodation, in accordance with your agreement with the Host.
            </li>
            <li>
                8.2.2 You agree to leave the Accommodation no later than the checkout date and time that the Host specifies in the Listing or such other time as mutually agreed upon between you and the Host. If you stay past the agreed upon checkout time without the Host's consent (“Overstay''), you no longer have a license to stay in the Accommodation and the Host is entitled to make you leave in a manner consistent with applicable law. In addition, you agree to pay, if requested by the Host, for each twenty-four (24) hour period (or any portion thereof) that you Overstay, an additional nightly fee of up to two (2) times the average nightly Listing Fee originally paid by you to cover the inconvenience suffered by the Host, plus all applicable Guest Fees, Taxes, and any legal expenses incurred by the Host to make you leave (collectively, "Overstay Fees''). Overstay Fees for late checkouts on the checkout date that do not impact upcoming bookings may be limited to the additional costs incurred by the Host as a result of such Overstay. If you Overstay at an Accommodation, you authorize Bongalo (via Bongalo Payments) to charge you to collect Overstay Fees. A Security Deposit, if required by a Host, may be applied to any Overstay Fees due for a Guest’s Overstay.
            </li>
        </ul>
    </p>

    <h4>
        8.3 Things to do and some experience
    </h4>
    <p>
        <ul>
            <li>
                8.3.1 Bongalo offers guests some things to do during their stay at a particular city per search queries, on their trips which may include but not limited to; restaurants, places to visit,  Guests acknowledge engaging in “things to do '' do not bind them under any agreement with Bongalo. They do so under their sole discretion and do not hold Bongalo liable for any inaccuracies of the “things to do ''.
            </li>
        </ul>
    </p>
</div>

<div id="booking-cancellations">
    <h2>9. Booking Cancellations and Refunds</h2>
    <p>
        <ul>
            <li>
                9.1 Hosts and Guests are responsible for any modifications to a booking that they make via the Bongalo Platform or direct Bongalo customer service to make ("Booking Modifications"), and agree to pay any additional Listing Fees, Host Fees or Guest Fees and/or Taxes associated with such Booking Modifications.
            </li>
            <li>
                9.2 Guests can cancel a confirmed booking at any time pursuant to the Listing’s cancellation policy set by the Host, and Bongalo will refund the amount of the Total Fees due to the Guest in accordance with such cancellation policy. This implies the following:
            </li>
            <ul>
                <li>
                    If the cancellation happens according to the time set by both Bogalo [72 hours before the arrival date] and by the host, the guest will receive a full refund(subtotal booking amount) minus the service fees and any associated transaction fees.
                </li>
                <li>
                    If the cancellation happens within the time set by the host but does not happen within the time set by Bongalo, the guest will receive a refund of the full amount they paid minus a service fee charged by Bongalo.
                </li>
            </ul>

            <li>
                Guests cancellation generally do not get a service fee refund. All service fees are non-refundable. 
            </li>

            <li>
                9.3 If a Host cancels a confirmed booking, the Guest will receive a full refund of the Total Fees for such booking. In addition, Bongalo may (i) keep the calendar for the Listing unavailable or blocked for the dates of the cancelled booking, and/or. Bongalo imposes cancellation fees on host cancellation where the guest meets all hosts requirements. 
            </li>
            <li>
                9.5 In certain circumstances, Bongalo may decide, in its sole discretion, that it is necessary to cancel a pending or confirmed booking and initiate corresponding refunds and payouts. This may be for reasons set forth (i) where Bongalo believes in good faith, while taking the legitimate interests of both parties into account, this is necessary to avoid significant harm to Bongalo, other Members, third parties or property, or (ii) for any of the reasons set out in these Terms.
            </li>
            <li>
                9.6 If a Guest who books an Accommodation suffers a Travel Issue, Bongalo may determine, in its sole discretion, to refund the Guest part or all of the Total Fees.
            </li>
            <li>
                9.8 Except as otherwise set out in these Terms, Members may contact our Support  to send or request money for refunds.
            </li>
            <li>
                9.9 Refunds are immediate on the part of Bongalo. However, refunds may take up to 15 business days to reflect on user’s accounts depending on the financial situation. 
            </li>
        </ul>
    </p>
</div>

<div>
    <h2>
        10. Ratings and Reviews
    </h2>
    <p>
        <ul>
            <li>
                10.1 Within a certain time frame after completing a booking, Guests and Hosts can leave a public review (“Review”) and submit a star rating (“Rating”) about each other. Ratings or Reviews reflect the opinions of individual Members and do not reflect the opinion of Bongalo. Ratings and Reviews are not verified by Bongalo for accuracy and may be incorrect or misleading.
            </li>
            <li>
                10.2 Ratings and Reviews by Guests and Hosts must be accurate and may not contain any offensive or defamatory language. 
            </li>
            <li>
                10.3 Members are prohibited from manipulating the Ratings and Reviews system in any manner, such as instructing a third party to write a positive or negative Review about another Member.
            </li>
            <li>
                10.4 Ratings and Reviews are part of a Member’s public profile and may also be surfaced elsewhere on the Bongalo Platform (such as the Listing page) together with other relevant information such as number of bookings, number of cancellations, and other information.
            </li>
        </ul>
    </p>
</div>

<div>
    <h2>
        11. Damage to Accommodations, Disputes between Members
    </h2>
    <p>
        <ul>
            <li>
                11.1 As a Guest, you are responsible for leaving the Accommodation (and any personal or other property located at the Accommodation) in the condition you met it. You are responsible for your own acts and omissions and are also responsible for the acts and omissions of any individuals whom you invite to, or otherwise provide access to, the Accommodation, excluding the Host (and the individuals the Host invites to the Accommodation, if applicable).
            </li>
            <li>
                11.2 If a Host claims and provides evidence that you as a Guest have damaged an Accommodation or any personal or other property at an Accommodation ("Damage Claim"), the Host can seek payment from you through the Resolution Center. If a Host escalates a Damage Claim to Bongalo, you will be given an opportunity to respond. Bongalo also reserves the right to otherwise collect payment from you and pursue any remedies available to Bongalo in this regard in situations in which you are responsible for a Damage Claim.
            </li>
            <li>
                11.3 Members agree to cooperate with and assist Bongalo in good faith, and to provide Bongalo with such information and take such actions as may be reasonably requested by Bongalo, in connection with any Damage Claims or other complaints or claims made by Members relating to (i) Accommodations or any personal or other property located at an Accommodation (including, without limitation, damages).
            </li>
        </ul>
    </p>
</div>

<div id="rounding-off">
    <h2>12. Rounding off</h2>
    <p>
        <ul>
            <li>
                Bongalo generally supports payment amounts that are payable from or to Guests or Hosts to the smallest unit supported by a given currency (i.e., U.S. cents, Euro cents or other supported currencies). Where Bongalo’s third-party payment services provider does not support payments in the smaller unit supported by a given currency, Bongalo may, in its sole discretion, round up or round down the displayed amounts that are payable from or to Guests or Hosts to the nearest whole functional base unit in which the currency is denominated (i.e. to the nearest dollar, Euro or other supported currency); for example, Bongalo may round up an amount of $101.50 to $102.00, and round down an amount of $101.49 to $101.00.
            </li>
        </ul>
    </p>
</div>
<div>
    <h2>
        13. Taxes
    </h2>
    <p>
        <ul>
            <li>
                13.1 As a Host you are solely responsible for determining your obligations to report, collect, remit or include in your Listing Fees any applicable VAT or other indirect sales taxes, occupancy tax, tourist or other visitor taxes or income taxes ("Taxes"). You are responsible for including these taxes when making a listing. 
            </li>
            <li>
                13.2 Tax regulations may require us to collect appropriate Tax information from Hosts, or to withhold Taxes from payouts to Hosts, or both. If a Host fails to provide us with the required documentation under applicable law (e.g., a tax number) that we determine to be sufficient to alleviate our obligation (if any) to withhold Taxes from payouts to you, we reserve the right to withhold payouts up to the tax-relevant amount as required by law, until resolution.
            </li>
            <li>
                13.6 Bongalo reserves the right, with prior notice to Hosts, to cease the Collection and Remittance in any jurisdiction for any reason at which point Hosts and Guests are once again solely responsible and liable for the collection and/or remittance of any and all Occupancy Taxes that may apply to Accommodations in that jurisdiction.
            </li>
        </ul>
    </p>
</div>

<div>
    <h2>
        14. Unauthorized activities
    </h2>
    <p>
        <ul>
            <li>
                14.1 You are solely responsible for compliance with any and all laws, rules, regulations, and Tax obligations that may apply to your use of the Bongalo Platform. Unauthorized uses of the Site also include, without limitation, those listed below. You agree not to do any of the following, unless otherwise previously and specifically agreed to by us:
            </li>

            <ul>
                <li>
                    offer, as a Host, any Accommodation that you do not yourself own or have permission to make available as a residential or other property through the Bongalo Platform;
                </li>
                <li>
                    breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms, Policies or Standards;
                </li>
                <li>
                    use the Bongalo Platform or Collective Content for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies Bongalo endorsement, partnership or otherwise misleads others as to your affiliation with Bongalo;
                </li>
                <li>
                    copy, store or otherwise access or use any information, including personally identifiable information about any other Member, contained on the Bongalo Platform in any way that is inconsistent with Bongalo’s Privacy Policy or these Terms or that otherwise violates the privacy rights of Members or third parties;
                </li>
                <li>
                    use the Bongalo Platform in connection with the distribution of unsolicited commercial messages ("spam");
                </li>
                <li>
                    unless Bongalo explicitly permits otherwise, book any Listing if you will not actually be using the Host Services yourself;
                </li>
                <li>
                    contact another Member for any purpose other than asking a question related to a your own booking, Listing, or the Member's use of the Bongalo Platform, including, but not limited to, recruiting or otherwise soliciting any Member to join third-party services, applications or websites, without our prior written approval;
                </li>
                <li>
                    use the Bongalo Platform to request, make or accept a booking independent of the Bongalo Platform, to circumvent any Service Fees or for any other reason;
                </li>
                <li>
                    request, accept or make any payment for Listing Fees outside of the Bongalo Platform or Bongalo Payments. If you do so, you acknowledge and agree that you: (i) would be in breach of these Terms; (ii) accept all risks and responsibility for such payment, and (iii) hold Bongalo harmless from any liability for such payment;
                </li>
                <li>
                    discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation, or otherwise engage in any violent, harmful, abusive or disruptive behavior;
                </li>
                <li>
                    misuse or abuse any Listings or services associated with the Bongalo.
                </li>
                <li>
                    use, display, mirror or frame the Bongalo Platform or Collective Content, or any individual element within the Bongalo Platform, Bongalo's name, any Bongalo trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the Bongalo Platform, without Bongalo's express written consent;
                </li>
                <li>
                    dilute, tarnish or otherwise harm the Bongalo brand in any way, including through unauthorized use of Collective Content, registering and/or using Bongalo or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Bongalo domains, trademarks, taglines, promotional campaigns or Collective Content;
                </li>
                <li>
                    use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Bongalo Platform for any purpose;
                </li>
                <li>
                    avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by Bongalo or any of Bongalo's providers or any other third party to protect the Bongalo Platform;
                </li>
                <li>
                    attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Bongalo Platform;
                </li>
                <li>
                    take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Bongalo Platform;
                </li>
                <li>
                    violate or infringe anyone else’s rights or otherwise cause harm to anyone through the Bongalo platform.
                </li>
            </ul>
            <li>
                Any use of the Site or the tools and services on the Site for the purpose of booking or soliciting a rental of a property other than a property listed under a valid subscription or pay-per-booking product;
            </li>
            <li>
                Copy, reproduce, upload, post, display, republish, distribute or transmit any part of the content in any form whatsoever;
            </li>
            <li>
                Reproduce any portion of the Site on your website or otherwise, using any device including, but not limited to, use of a frame or border environment around the Site, or other framing technique to enclose any portion or aspect of the Site, or mirror or replicate any portion of the Site;
            </li>
            <li>
                Deep-link to any portion of the Site without our express written permission;
            </li>
            <li>
                Modify, translate into any language or computer language or create derivative works from, any content or any part of the Site;
            </li>
            <li>
                Reverse engineer any part of the Site;
            </li>
            <li>
                Sell, offer for sale, transfer or license any portion of the Site in any form to any third parties;
            </li>
            <li>
                Use the Site and its inquiry or booking functionality other than to advertise and/or research vacation rentals, to make legitimate inquiries to our members or any other use expressly authorized on the Site;
            </li>
            <li>
                Use the Site to post or transmit information that is in any way false, fraudulent, or misleading, including making any reservation or inquiry under false pretenses, or taking any action that may be considered phishing or that would give rise to criminal or civil liability;
            </li>
            <li>
                Post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic or profane material;
            </li>
            <li>
                Violate, plagiarize or infringe the rights of us or third parties including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or privacy or any other intellectual or proprietary rights; or
            </li>
            <li>
                Use or access the Site in any way that, in our sole discretion, adversely affects, or could adversely affect, the performance or function of the Site or any other system used by us or the Site.
            </li>
            <li>
                You acknowledge that Bongalo has no obligation to monitor the access to or use of the Bongalo Platform by any Member or to review, disable access to, or edit any Member Content, but has the right to do so to (i) operate, secure and improve the Bongalo Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Members’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to Member Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Members agree to cooperate with and assist Bongalo in good faith, and to provide Bongalo with such information and take such actions as may be reasonably requested by Bongalo with respect to any investigation undertaken by Bongalo or a representative of Bongalo regarding the use or abuse of the Bongalo Platform.
            </li>
            <li>
                If you are aware of, or experience, any content, activity or communication through or in connection with the Site that appears to be in violation of the above restrictions, or in violation of any other provision of these Terms, we ask that you please inform us by contacting us at the address below…………….
            </li>
        </ul>
    </p>

</div> 
 

<div id="termination" >
    <h2>
        15. Term and Termination, Suspension and other Measures
    </h2>
    <p>
        <ul>
            <li>
                15.1 This Agreement shall be effective for a 30-day term, at the end of which it will automatically and continuously renew for subsequent 30-day terms until such time when you or Bongalo terminate the Agreement in accordance with this provision.
            </li>
            <li>
                15.2 You may terminate this Agreement at any time by sending us an email. If you cancel your Bongalo Account as a Host, any confirmed booking(s) will be automatically cancelled and your Guests will receive a full refund. If you cancel your Bongalo Account as a Guest, any confirmed booking(s) will be automatically cancelled and any refund will depend upon the terms of the Listing’s cancellation policy.
            </li>
            <li>
                15.3 Without limiting our rights specified below, Bongalo may terminate this Agreement for convenience at any time by giving you thirty (30) days' notice via email to your registered email address.
            </li>
            <li>
                15.4 Bongalo may immediately, without notice, terminate this Agreement and/or stop providing access to the Bongalo Platform if (i) you have materially breached your obligations under these Terms, the Payments Terms, our Policies , (ii) you have violated applicable laws, regulations or third party rights, or (iii) Bongalo believes in good faith that such action is reasonably necessary to protect the personal safety or property of Bongalo, its Members, or third parties (for example in the case of fraudulent behavior of a Member).
            </li>
            <li>
                15.5 In addition, Bongalo may take any of the following measures (i) to comply with applicable law, or the order or request of a court, law enforcement or other administrative agency or governmental body, or if (ii) you have breached these Terms, the Payments Terms, our Policies or Standards, applicable laws, regulations, or third party rights, (iii) you have provided inaccurate, fraudulent, outdated or incomplete information during the Bongalo Account registration, Listing process or thereafter, (iv) you and/or your Listings or Host Services at any time fail to meet any applicable quality or eligibility criteria, (v) you have repeatedly received poor Ratings or Reviews or Bongalo otherwise becomes aware of or has received complaints about your performance or conduct, (vi) you have repeatedly cancelled confirmed bookings or failed to respond to booking requests without a valid reason, or (vii) Bongalo believes in good faith that such action is reasonably necessary to protect the personal safety or property of Bongalo, its Members, or third parties, or to prevent fraud or other illegal activity:
            </li>
            <ul>
                <li>
                    refuse to surface, delete or delay any Listings, Ratings, Reviews, or other Member Content;
                </li>
                <li>
                    cancel any pending or confirmed bookings;
                </li>
                <li>
                    limit your access to or use of the Bongalo Platform;
                </li>
                <li>
                    temporarily or permanently revoke any special status associated with your Bongalo Account;
                </li>
                <li>
                    temporarily or in case of severe or repeated offenses permanently suspend your Bongalo Account and stop providing access to the Bongalo Platform.
                </li>
                
            </ul>
            <li>
                    In case of non-material breaches and where appropriate, you will be given notice of any intended measure by Bongalo and an opportunity to resolve the issue to Bongalo's reasonable satisfaction.
            </li>
            <li>
                15.6 If we take any of the measures described above (i) we may refund your Guests in full for any and all confirmed bookings that have been cancelled, irrespective of preexisting cancellation policies, and (ii) you will not be entitled to any compensation for pending or confirmed bookings that were cancelled.
            </li>
            <li>
                15.7 When this Agreement has been terminated, you are not entitled to a restoration of your Bongalo Account or any of your Member Content. If your access to or use of the Bongalo Platform has been limited or your Bongalo Account has been suspended or this Agreement has been terminated by us, you may not register a new Bongalo Account or access and use the Bongalo Platform through an Bongalo Account of another Member.
            </li>
            <li>
                15.8 Sections 5 and 16 to 22 of these Terms shall survive any termination or expiration of this Agreement.
            </li>
            <li>
                You agree that we shall take 10% of booking subtotal
            </li>

            <li>
                You agree that we shall collect a service fee from you, determined by our system.
            </li>
        </ul>
    </p>
</div>

        </div>
        
    </div>
</template>


<script>
import Navigation from '../components/Blog/Navigation';

export default {
     name:'terms-condition',
     components:{
         Navigation,
     },
     data:function(){
         return {
             
         }
     }
     
}
</script>


<style lang='scss' scoped>

    a{
        text-decoration: none;
        
    }
    a:visited{
        color: #3A85FC;
    }
    .terms-condition {
        width:100%;

        .content{
            margin: 0 150px;
            border-left: 1px solid rgb(228, 227, 227);
            border-right: 1px solid rgb(228, 227, 227);
            // border:1px solid red;

            padding: 50px 50px;

            h1{
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 42px;

                color: #404040;
                margin-bottom: 30px;
            }

            .side-nav{
                margin-bottom: 30px;
                width:100%;
                .item-a{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 35px;
                /* or 225% */

                text-align: justify;

                color: #3A85FC;

            }

            }
            h2{
                margin-top: 10px;;
            }

            li, p{
                width:90%;
                margin-top: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                /* or 175% */

                text-align: left;

                color: #404040;
            }
            li{
                margin-top: 10px;;
            }
            
        }
    }
</style>